import React from "react";
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import '@fortawesome/fontawesome-free/css/all.min.css';

class Payment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {type: "paypal"}
    }
    onClickType = (type) => {
        this.setState({type: type});
    }
    render () {
        const {type} = this.state;
        return (
            <div className="payment">
                <div className="paymentSupport">
                    <a className={(type  == "paypal") ? "payPal active" : "payPal"} onClick={() => this.onClickType("paypal")}></a>
                    <a className={(type  == "transfer") ? "transferWise active" : "transferWise"} onClick={() => this.onClickType("transfer")}></a>
                </div>
                <div className="paymentInfo">
                    {type == "paypal" ?
                    <div className="payPalInfo">
                        <a href="#price"><span>Price:</span> Please select price of license below.</a>
                        <a><span>PayPal Address:</span> truongdungabc.vd9@gmail.com</a>
                        <a className="nodePayment">
                            *** When your payment is complete, Pls contact me to receive the app ***
                            <span><i className="fas fa-envelope"></i>Email: odoo.studio.community@gmail.com</span>
                            <span><i className="fab fa-skype"></i>Skype: dungtv1993 (Tony Truong)</span>
                            <span><i className="fab fa-whatsapp"></i>Whatsapp: +84365640696</span>
                        </a>
                    </div> : null }
                    {type == "transfer" ?
                    <div className="transferWiseInfo">
                        <a href="#price"><span>Price:</span> Please select price of license below.</a>
                        <a><span>Account:</span> odoo.studio.community@gmail.com</a>
                        <a><span>Account Holder:</span> TRUONG VAN DUNG</a>
                        <a><span>My bank:</span> Asia Commercial Bank (ACB)</a>
                        <a><span>Bank currency:</span> VND</a>
                        <a><span>Bank no:</span> 220338499</a>
                        <a className="nodePayment">
                            *** When your payment is complete, Pls contact me to receive the app ***
                            <span><i className="fas fa-envelope"></i>Email: odoo.studio.community@gmail.com</span>
                            <span><i className="fab fa-skype"></i>Skype: dungtv1993 (Tony Truong)</span>
                            <span><i className="fab fa-whatsapp"></i>Whatsapp: +84365640696</span>
                        </a>
                    </div> : null }

                </div>
            </div>
        )
    }
}

class FooterSection extends React.Component {
    constructor(props) {
        super(props);
        this.des = {
            cp: "© 2020 Odoo Studio, Inc. All Rights Reserved",
            term: {
                guides: {label: 'Guides'},
                ts: {label: 'Terms of Sale'},
                tu: {label: 'Terms of Use'},
                pp: {label: 'Yee Privacy Policy'}
            }
        }
    }

    render() {
        return (
            <footer>
                <div className="footer">
                    <div className="container">
                        <div className="cpRight">
                            <span>{this.des.cp}</span>
                        </div>
                        <div id="contact" className="contact">
                            <span><i className="fas fa-envelope"></i>Email: odoo.studio.community@gmail.com</span>
                            <span><i className="fab fa-skype"></i>Skype: dungtv1993 (Tony Truong)</span>
                            <span><i className="fab fa-whatsapp"></i>Whatsapp: +84365640696</span>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

class HeaderSection extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="section section-header section-all-products section-category gradient-blue">
            </div>
        )
    }
}

class Pricing extends React.Component {
    constructor(props) {
        super(props);
        this.initPrams();
    }

    initPrams() {
        this.options = {
            started: {
                label: "Standard",
                icon: "starter",
                des: "For a quick & easy way to share and take brief notes",
                price: "500€",
                features: ["All Features", "Priority Support", "90days Support"]
            },
            unlimited: {
                label: "Professional",
                icon: "unlimited",
                des: "For an expansive and shared knowledge library",
                price: "700€",
                features: ["All Features", "Founder Support", "1year Support", "Upgrade Features free", "get Dashboard Studio free"]
            },
            lifetime: {
                label: "VIP Lifetime",
                icon: "lifetime",
                des: "For true knowledge and note-taking veterans",
                price: "800€",
                features: ["All Features", "Founder Support", "3years Support", "Upgrade Features free", "Upgrade Version Free", "get Dashboard Studio free"]
            }
        };
    }

    render() {
        return (
            <div className="wPricing" id="price">
                <div>
                    <div className="wTitle">
                        <div className="titleCon">
                            <div className="titleIcon">
                                <img src="icons/pricing.svg"/>
                            </div>
                            <h1>Pricing</h1>
                        </div>
                        <div className="titleDes">
                            <h3>Odoo Studio. Customize and Build Odoo Apps on the fly without any technical
                                knowledge!</h3>
                        </div>
                    </div>
                    <div className="wCon">
                        <div className="wPr">
                            {Object.keys(this.options).map((optionName, key) => {
                                const option = this.options[optionName];
                                return <div className="prOption" key={key} type={optionName}>
                                    <div className="wIcon">
                                        <img src={"icons/" + option.icon + ".svg"}/>
                                    </div>
                                    <div className="wInfo">
                                        <h3>{option.label}</h3>
                                        {/*<p>{option.des}</p>*/}
                                        <div className="price">
                                            <span>{option.price}</span>
                                        </div>
                                    </div>
                                    <div className="wFeatures">
                                        {option.features.map((feature, idx) => {
                                            return <div key={idx}><i className="fa fa-check"/> {feature}</div>
                                        })}
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class ProductInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {showPayment: false};
    }

    render() {
        return (
            <div className="main">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="o_title">
                                <h1>Odoo Studio</h1>
                            </div>
                            <div className="o_description">
                                <p>Odoo Studio for Community - Customize and Build Odoo Apps on the fly without any technical
                                    knowledge</p>
                            </div>
                            <div className="o_buttons">
                                <a className="btnBuy" href="https://truongvandung.com/apps/modules/18.0/3a40c070ddbaca7e15ebfdf28dc61f809beac5807d43c37e690cde91c2d5f3fd" target="new">Buy
                                    Now</a>
                                <a className="btnWatch" href="https://youtu.be/fQrahsTJ1qY?si=fMHdHbwTGOjQeOi-" target="blank">Watch
                                    Video</a>
                            </div>
                            <div className="o_more">
                                <p>Versions support: 12, 13, 14, 15, 16, 17, 18</p>
                            </div>
                            { this.state.showPayment ?
                                <div className="o_payment">
                                    <Payment/>
                                </div> : null
                            }
                        </div>
                        <div className="col-md-12 o_overview">
                            <div className="imgLove">
                                <img src="love.svg"/>
                            </div>
                            <div className="imgOverview">
                                <img src="overview.png"/>
                            </div>
                            <div className="imgLaying">
                                <img src="laying.svg"/>
                            </div>
                        </div>

                    </div>
                    <div className="row mt92 area_features">
                        <div className="col-lg-12 text-center wHead">
                            <h2 className="display-3">All you need to <b>build custom applications.</b></h2>
                            <h4 className="mb64">You become the developer.</h4>
                        </div>
                        <div className="col-lg-4 mb48 text-center">
                            <i className="fa fa-2x fa-cube o-icon text-gamma mb8"></i>
                            <h5>Create beautiful Applications</h5>
                            <p>Design full featured apps <br/>in minutes, not weeks.</p>
                        </div>
                        <div className="col-lg-4 mb48 text-center">
                            <i className="fa fa-2x fa-file o-icon text-gamma mb8"></i>
                            <h5>Design awesome Reports</h5>
                            <p>Drag and drop authoring&nbsp;<br/>with our report builder.</p>
                        </div>
                        <div className="col-lg-4 mb48 text-center">
                            <i className="fa fa-2x fa-edit o-icon text-gamma mb8"></i>
                            <h5>Customize advanced Screens</h5>
                            <p>Graphs, kanban, pivot, tables<br/>charts, form, calendar etc.</p>
                        </div>
                        <div className="col-lg-4 mb48 text-center">
                            <i className="fa fa-2x fa-gear o-icon text-gamma mb8"></i>
                            <h5>Automate business Workflows</h5>
                            <p>Add business logic to your application, automate tasks with no development.</p>
                        </div>
                        <div className="col-lg-4 mb48 text-center">
                            <i className="fa fa-2x fa-rocket o-icon text-gamma mb8"></i>
                            <h5>Increase Productivity</h5>
                            <p>Boost your app with an email gateway, <br/>the report engine, BI, workflows, etc.</p>
                        </div>
                        <div className="col-lg-4 mb48 text-center">
                            <i className="fa fa-2x fa-mobile o-icon text-gamma mb8"></i>
                            <h5>Mobile Ready</h5>
                            <p>Apps automatically adjust to smaller&nbsp;<br/>screens using our responsive user
                                interface.</p>
                        </div>
                    </div>
                    <div className="row">
                        <Pricing/>
                    </div>
                    <div className="row wDashboard">
                        <div className="wTitle mb48">
                            <div className="titleCon">
                                <div className="titleIcon"><i className="fa fa-dashboard"/></div>
                                <h1><a target="blank" href="https://youtu.be/rHU0VOW52FQ">Dashboard
                                    Studio</a></h1></div>
                            <div className="titleDes">
                                <h3>Build Dashboard View on the fly without any technical knowledge!</h3>
                            </div>
                            <div className="o_more">
                                <p>Support in Professional and VIP license</p>
                            </div>
                        </div>
                        <div className="imgOverview"><a target="blank"
                                                        href="https://youtu.be/rHU0VOW52FQ"><img
                            src="dashboard_overview.png"/></a></div>
                    </div>
                </div>
            </div>
        )
    }
}

class ContentSection extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <ProductInformation/>
            </>
        )
    }
}

function App() {
    return (
        <div className="APp studio">
            <HeaderSection/>
            <ContentSection />
            <FooterSection/>
        </div>
    );
}

export default App;
